import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import AdminContainer from '../../../layout/admin/container';
import { useBreadcrumbs } from '../../../../contexts/breadcrumbs-provider';
import Heading from '../../../layout/admin/heading';
import AutoForm from '../../../form/auto-form';
import { getCenterAdminToken } from '../../../../utils/auth';
import SEO from '../../../layout/seo';

const CenterAdminChooseLeague = () => {
  const { setBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    setBreadcrumbs([
      {
        href: '/app/center-admin/products',
        label: 'Products',
      },
      {
        href: `/app/center-admin/products/choose-league`,
        label: `Choose League`,
      },
    ]);
  }, []);


  const chooseLeagueForm = {
    type: 'get',
    successHandler: (values) => {
      // console.log(values, 'we are about to redirect');
      const { league_id: leagueId } = values;
      navigate(`/app/center-admin/products/league/create?id=${leagueId}`);
    },
    fields: [
      {
        name: 'league_id',
        label: 'League',
        type: 'select',
        optionsUrl: `/options/leagues?password=${getCenterAdminToken()}`,
        helpText: 'To get started, choose the league for your new product.',
        required: true,
      },
    ],
    submitLabel: 'Create New Product',
  };

  return (
    <AdminContainer centerAdmin>
      <SEO title="Choose league" />
      <Heading title="Choose League" />
      <div className="max-w-2xl mx-auto py-12">
        <AutoForm {...chooseLeagueForm} />
      </div>
    </AdminContainer>
  );
}

export default CenterAdminChooseLeague;
